import { useQuery } from '@apollo/client';
import { useParams, Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Typography } from '@material-tailwind/react';
import Markdown from 'react-markdown';
import { formatDistanceToNow } from 'date-fns';
import { FaLink } from 'react-icons/fa6';
import { COMPANY_GET } from './Queries.js';
import Skeleton from '../Components/Skeleton.jsx';
import NotFound from '../Components/NotFound.jsx';
import { useQueryParams } from '../Hooks/index.js';
import EventsWidget from '../Events/Widget.jsx';

const View = () => {
  const { groupId } = useParams();
  const [{ eventsAfter, eventsBefore }] = useQueryParams(['eventsAfter', 'eventsBefore']);

  const { data, loading } = useQuery(COMPANY_GET, { variables: { groupId, eventsAfter, eventsBefore } });

  if (loading) return <Skeleton />;

  if (!data || !data.company) return <NotFound message="Company not found." />;

  const { name, traits, events, conversations, conversationAnalysis, people } = data.company;

  return (
    <Card className="h-full w-full">
      <CardHeader floated={false} shadow={false} className="rounded-none">
        <div className="flex flex-col justify-between gap-8 md:flex-row md:items-center">
          <div>
            <Typography variant="h5" color="blue-gray">
              {name}
            </Typography>
          </div>
        </div>
      </CardHeader>
      <CardBody className="grid grid-cols-12 gap-4 px-4">
        <div className="col-span-3">
          <Card>
            <CardBody>
              <Typography variant="h5">Details</Typography>

              {Number.isFinite(data.company.score) ? (
                <div className="mt-4">
                  <Typography variant="small">Score</Typography>
                  <Typography variant="h6">
                    {data.company.score} / 10
                    <span className="ml-1">
                      {data.company.score > 6 ? '🟢' : null}
                      {data.company.score < 4 ? '🔴' : null}
                      {data.company.score > 3 && data.company.score < 7 ? '🟡' : null}
                    </span>
                  </Typography>
                  <Typography variant="small">{data.company.reason}</Typography>
                </div>
              ) : null}

              <div className="mt-4">
                <Typography variant="small">Conversations</Typography>
                <Typography variant="h6">
                  {conversations?.edges.length}
                  {conversations?.pageInfo?.hasNextPage ? '+' : ''}
                  <Link to="https://app.intercom.com/" target="_blank" className="hover:underline">
                    <FaLink className="inline-block ml-1" />
                  </Link>
                </Typography>
              </div>

              {Object.entries(traits ?? {}).map(([key, value], index) => (
                <div key={index} className="mt-4">
                  <Typography variant="small">{key}</Typography>
                  <Typography variant="h6">{value}</Typography>
                </div>
              ))}
            </CardBody>
          </Card>

          <Card className="mt-4">
            <CardBody>
              <Typography variant="h5">People</Typography>

              {people?.edges.map(({ node: person }) => (
                <div key={person.userId} className="mt-4">
                  <Typography variant="small">{person.email}</Typography>
                  <Typography variant="h6">
                    <Link to={`/people/${encodeURIComponent(person.userId)}`} className="hover:underline">
                      {person.name}
                    </Link>
                  </Typography>
                  <Typography variant="small">Last seen: {formatDistanceToNow(person.lastSeenAt, { addSuffix: true })}</Typography>
                </div>
              ))}
            </CardBody>
          </Card>
        </div>

        <div className="col-span-9">
          <Card className="mt-4 first:mt-0">
            <CardBody>
              <Typography variant="h5" color="blue-gray" className="mb-4">
                Conversations analysis
              </Typography>
              <Typography variant="small" color="blue-gray">
                {conversationAnalysis ? (
                  <Markdown className="leading-6">{conversationAnalysis}</Markdown>
                ) : (
                  <Typography variant="small" color="blue-gray">
                    No conversation analysis available.
                  </Typography>
                )}
              </Typography>
            </CardBody>
          </Card>

          {events?.edges.length > 0 ? <EventsWidget events={events} loading={loading} /> : null}
        </div>
      </CardBody>
    </Card>
  );
};

export default View;
